import React, { useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';

import useDateFormat from 'src/hooks/useDateFormat';
import useDowngradeInventory from 'src/hooks/useDowngradeInventory';
import useSelectedLevel from 'src/hooks/useSelectedLevel';
import { trackEvent } from 'src/services/tracker';
import { useAuthContext } from 'src/components/AuthProvider';
import Banner from 'src/components/molecule/Banner';
import PlanLevelWidget from 'src/components/PlanLevelWidget';
import { Translate } from 'src/components/Translate';
import { ProductRadioSelect } from 'src/components/organism';
import Breadcrumbs from 'src/components/Breadcrumbs';
import useMembership from 'src/api/useMembership';
import useActiveProduct from 'src/api/useActiveProduct';
import useProfile from 'src/api/useProfile';
import logTrackedEvent from 'src/helpers/logTrackedEvent';
import showRenewalEntry from 'src/helpers/showRenewalEntry';
import useRedirect from 'src/hooks/useRedirect';
import { SimpleFooter } from 'src/features/footer';

function RenewPlan({ location }: PageProps) {
  const { isAuthenticated, loading: loadingUser } = useAuthContext();
  const { state } = location;

  const [level, setLevel] = useSelectedLevel(1);
  const [banner, setBanner] = useState(null);
  const nextProduct = useDowngradeInventory();

  const { product, loading: productLoading } = useActiveProduct();
  const { data: profile } = useProfile();
  const { data: membership, ...membershipQuery } = useMembership();
  const format = useDateFormat();

  const expireAt = format(membership?.expireAt);

  const isOneYearVip = membership?.isOneYearVip();

  useRedirect('/my-account/');

  useEffect(() => {
    setLevel(isOneYearVip ? 3 : 1);
  }, [isOneYearVip]);

  const message = state?.['msg'];
  useEffect(() => {
    if (message) {
      membershipQuery.stopPolling();
      setBanner({ display: true, message, color: 'success' });
    }
  }, [message]);

  const i18n = {
    breadcrumb: 'breadcrumbs.myAccount.text',
    headline: 'manageMembership.renewalPlan.headline',
    leadText: 'manageMembership.leadText',
    renewalText: 'manageMembership.renewalText',
    downgradeText: 'manageMembership.downgradeText',
    expireText: 'manageMembership.expireText',
    buttonText: 'choosePlan.buttonText',
  };

  const onSubmit = () => {
    const inventory = product.mileages[level - 1];
    logTrackedEvent(trackEvent, {
      _tag_name: 'HDC Choose Plan',
      product_code: inventory.name,
      price: inventory.price.amount,
    });
    return navigate('/my-account/renew-plan/confirm/', { state: { nav: true } });
  };

  useEffect(() => {
    // We need to get the membership from server before trying to do any change
    // only do so when auth tokens are ready
    if (isAuthenticated) membershipQuery.refetch();
  }, [isAuthenticated]);

  // When the user navigates directly to the URL the membership request takes
  // a few ms to be made resulting in loading=false, membership=null momentarily
  // This is a way yo detect if membership was really called
  useEffect(() => {
    if (!loadingUser && !membershipQuery.loading) {
      const unauthenticated = !loadingUser && !isAuthenticated;
      const hasMembership = membership?.hasMembership();
      if (unauthenticated || hasMembership === false) {
        navigate('/checkout/', { replace: true });
      } else if (!showRenewalEntry(membership)) {
        // This page is only accessible to one-year VIP w/o renewal plan
        navigate('/my-account/renew-plan/', { replace: true });
      }
    }
  }, [loadingUser, isAuthenticated, membership]);

  return (
    <>
      {banner?.display && <Banner banner={banner} setBanner={setBanner} />}
      <div className="container container_center">
        <Breadcrumbs originTransKey={i18n.breadcrumb} originPath={'/my-account/'} />
        <h2 className="text-display_2">
          <Translate resourceKey={i18n.headline} />
        </h2>
        {isAuthenticated && membership && <PlanLevelWidget membership={membership} profile={profile} showDate />}
        {nextProduct && (
          <span className="text_2" data-cy="downgrade-text">
            <Translate
              resourceKey={i18n.downgradeText}
              values={{
                gear: nextProduct.name,
                date: expireAt,
              }}
            />
          </span>
        )}
        {membership?.willBeCanceled() && (
          <span className="text_2" data-cy="expire-text">
            <Translate
              resourceKey={i18n.expireText}
              values={{
                date: expireAt,
              }}
            />
          </span>
        )}
        {membership?.hasMembership() && !membership.willBeCanceled() && !nextProduct && (
          <span className="text_2" data-cy="renewal-text">
            <Translate
              resourceKey={i18n.renewalText}
              values={{
                date: expireAt,
              }}
            />
          </span>
        )}
        <div className="inset-s" />
        <p className="text_2" data-cy="membership-lead-text">
          <Translate resourceKey={i18n.leadText} />
        </p>
        {!membershipQuery.loading && !productLoading && (
          <ProductRadioSelect membership={membership} value={level} onChange={setLevel} />
        )}
        <div className="inset-s" />
        <button
          type="submit"
          data-cy="choosePlanButton"
          disabled={!level || level === membership?.level || membership?.willBeDowngraded()}
          className="button button_primary button_primary--large submit-button"
          onClick={onSubmit}
        >
          <Translate resourceKey={i18n.buttonText} />
        </button>
      </div>
      <div className="inset-l" />

      <SimpleFooter />
    </>
  );
}

export default RenewPlan;
